<template>
	<div class="main" align="center">
		
		
		<div class="inner main menu">
		<dl>
		<dt class="line-60">{{typeName[type]}}六合彩开奖记录</dt>
		</dl>
		<span class="drop-years-menu icon-chevron-down  text-white"></span>
		</div>
		
		<div class="content">
			<div class="main">
				<article class="table-area" id="tableArea">
					<div class="cgi-wrap">
						<div id="whiteBox" class="white-box" style="opacity: 1;">
							
							<div v-for="(item,index) in article2">
								<div class="kj-tit" style="height: unset;">
									六合彩开奖记录 {{item.date_time}} 第<span class="text-blue text-strong">{{item.qisu}}</span>
								</div>
								<div class="kj-box">
									<ul class="clearfix">
										<li>
											<dl>
												<dt :class="styles[item.haoma1[0]] != undefined ? styles[item.haoma1[0]] : ''">
													<span>{{item.haoma1[0]}}</span>
												</dt>
												<dd>{{item.haoma1[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma1[1][1]] != undefined ? styles2[item.haoma1[1][1]] : ''">{{item.haoma1[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
										<li>
											<dl>
												<dl>
													<dt :class="styles[item.haoma2[0]] != undefined ? styles[item.haoma2[0]] : ''">
														<span>{{item.haoma2[0]}}</span>
													</dt>
													<dd>{{item.haoma2[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma2[1][1]] != undefined ? styles2[item.haoma2[1][1]] : ''">{{item.haoma2[1][1]}}</font>
													</dd>
													
												</dl>
												
											</dl>
										</li>
										<li>
											<dl>
												<dt :class="styles[item.haoma3[0]] != undefined ? styles[item.haoma3[0]] : ''">
													<span>{{item.haoma3[0]}}</span>
												</dt>
												<dd>{{item.haoma3[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma3[1][1]] != undefined ? styles2[item.haoma3[1][1]] : ''">{{item.haoma3[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
										<li>
											<dl>
												<dt :class="styles[item.haoma4[0]] != undefined ? styles[item.haoma4[0]] : ''">
													<span>{{item.haoma4[0]}}</span>
												</dt>
												<dd>{{item.haoma4[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma4[1][1]] != undefined ? styles2[item.haoma4[1][1]] : ''">{{item.haoma4[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
										<li>
											<dl>
												<dt :class="styles[item.haoma5[0]] != undefined ? styles[item.haoma5[0]] : ''">
													<span>{{item.haoma5[0]}}</span>
												</dt>
												<dd>{{item.haoma5[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma5[1][1]] != undefined ? styles2[item.haoma5[1][1]] : ''">{{item.haoma5[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
										<li>
											<dl>
												<dt :class="styles[item.haoma6[0]] != undefined ? styles[item.haoma6[0]] : ''">
													<span>{{item.haoma6[0]}}</span>
												</dt>
												<dd>{{item.haoma6[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma6[1][1]] != undefined ? styles2[item.haoma6[1][1]] : ''">{{item.haoma6[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
										<li class="kj-jia">
											<dl>
												<dt></dt>
												<dd></dd>
												<dd style="display: none"></dd>
												<dd style="display: none"></dd>
												<dd style="display: none"></dd>
											</dl>
										</li>
										<li>
											<dl>
												<dt :class="styles[item.haoma7[0]] != undefined ? styles[item.haoma7[0]] : ''">
													<span>{{item.haoma7[0]}}</span>
												</dt>
												<dd>{{item.haoma7[1][0]}}<font class="grey-txt">/</font>
													<font :class="styles2[item.haoma7[1][1]] != undefined ? styles2[item.haoma7[1][1]] : ''">{{item.haoma7[1][1]}}</font>
												</dd>
												
											</dl>
										</li>
									</ul>
								</div>

							</div>
						</div>
					</div>
				</article>
			</div>
		</div>
	</div>
</template>
<script>
	//port "@videojs/http-streaming"

	import {
		indexFn
	} from "/api/index.js";
	export default {
		name: 'board',
		components: {

		},
		data() {
			return {
				date: '',
				time: '',
				week: '',
				article: {},
				actionUrl: 'https://zhibo.2020kj.com:777/amkj2.html',
				cur: 1,
				logo: "images/08cfe3149b4d15a0c004caeabc12af2c.png",
				adverts: [],
				article2: [
					
				],
				type:1,
				typeName:{
					1:'香港六合彩',
					2:'澳门六合彩'
				},
				styles:{
					'01':'bg_red',
					'02':'bg_red',
					'23':'bg_red',
					'24':'bg_red',
					'40':'bg_red',
					'13':'bg_red',
					'35':'bg_red',
					'12':'bg_red',
					'19':'bg_red',
					'07':'bg_red',
					'08':'bg_red',
					'29':'bg_red',
					'30':'bg_red',
					'45':'bg_red',
					'46':'bg_red',
					'18':'bg_red',
					'34':'bg_red',
					
					'09':'bg_blue',
					'10':'bg_blue',
					'31':'bg_blue',
					'14':'bg_blue',
					'36':'bg_blue',
					'20':'bg_blue',
					'41':'bg_blue',
					'42':'bg_blue',
					'15':'bg_blue',
					'37':'bg_blue',
					'03':'bg_blue',
					'04':'bg_blue',
					'25':'bg_blue',
					'26':'bg_blue',
					'47':'bg_blue',
					'48':'bg_blue',
					
					'32':'bg_green',
					'39':'bg_green',
					'05':'bg_green',
					'06':'bg_green',
					'21':'bg_green',
					'22':'bg_green',
					'43':'bg_green',
					'44':'bg_green',
					'11':'bg_green',
					'27':'bg_green',
					'28':'bg_green',
					'49':'bg_green',
					'16':'bg_green',
					'38':'bg_green',
					'17':'bg_green',
					'33':'bg_green',

					
				},
				styles2:{
					'火' : 'wx-huo',
					'木' : 'wx-mu',
					'土' : 'wx-tu',
					'金' : 'wx-jin',
					'水' : 'wx-shui',
				}

			}
		},
		computed: {

		},
		
		mounted(data) {
			
			console.log('query',this.$route.query)
			let querData = this.$route.query
			let type = querData.type || 1
			type = 3 -type;
			let routeUrl = 'getnewdata'
			
			if(type == 2 ){
				routeUrl = 'getamlist'
			}
			this.type = type
			let that = this
			indexFn({}, routeUrl)
			.then((res) => {
				
				let article2 = res.data
				console.log('article2',article2)
				article2.forEach(function(value,index){
					
					value.haoma1 = value.haoma1.split(',')
					value.haoma2 = value.haoma2.split(',')
					value.haoma3 = value.haoma3.split(',')
					value.haoma4 = value.haoma4.split(',')
					value.haoma5 = value.haoma5.split(',')
					value.haoma6 = value.haoma6.split(',')
					value.haoma7 = value.haoma7.split(',')
					
					if(value.haoma1[1] != undefined) {
						value.haoma1[1] = value.haoma1[1].split('/')
					}
					
					if(value.haoma2[1] != undefined) {
						value.haoma2[1] = value.haoma2[1].split('/')
					}
					
					if(value.haoma3[1] != undefined) {
						value.haoma3[1] = value.haoma3[1].split('/')
					}
					
					if(value.haoma4[1] != undefined) {
						value.haoma4[1] = value.haoma4[1].split('/')
					}
					
					if(value.haoma5[1] != undefined) {
						value.haoma5[1] = value.haoma5[1].split('/')
					}
					
					if(value.haoma6[1] != undefined) {
						value.haoma6[1] = value.haoma6[1].split('/')
					}
					
					if(value.haoma7[1] != undefined) {
						value.haoma7[1] = value.haoma7[1].split('/')
					}
					
					
					console.log('value',value)
					
				})
				
				this.article2 = article2
				
				this.$forceUpdate();
			})
			.catch((err) => {
				console.log("err", err);
			});
			
			
			
			
			/*
			let article2 = [{
				qisu:'121',
				shijian:'2023年05月15日',
				haoma1:'08,猴/火',
				haoma2:'06,狗/木',
				haoma3:'03,牛/土',
				haoma4:'09,羊/金',
				haoma5:'12,龙/水',
				haoma6:'27,牛/水',
				haoma7:'07,鸡/火',
				
			}]
			
			article2.forEach(function(value,index){
				
				value.haoma1 = value.haoma1.split(',')
				value.haoma2 = value.haoma2.split(',')
				value.haoma3 = value.haoma3.split(',')
				value.haoma4 = value.haoma4.split(',')
				value.haoma5 = value.haoma5.split(',')
				value.haoma6 = value.haoma6.split(',')
				value.haoma7 = value.haoma7.split(',')
				
				if(value.haoma1[1] != undefined) {
					value.haoma1[1] = value.haoma1[1].split('/')
				}
				
				if(value.haoma2[1] != undefined) {
					value.haoma2[1] = value.haoma2[1].split('/')
				}
				
				if(value.haoma3[1] != undefined) {
					value.haoma3[1] = value.haoma3[1].split('/')
				}
				
				if(value.haoma4[1] != undefined) {
					value.haoma4[1] = value.haoma4[1].split('/')
				}
				
				if(value.haoma5[1] != undefined) {
					value.haoma5[1] = value.haoma5[1].split('/')
				}
				
				if(value.haoma6[1] != undefined) {
					value.haoma6[1] = value.haoma6[1].split('/')
				}
				
				if(value.haoma7[1] != undefined) {
					value.haoma7[1] = value.haoma7[1].split('/')
				}
				
				
				console.log('value',value)
				
			})
			
			this.article2 = article2
			*/

		},

		beforeDestroy() {

		},
		methods: {

			Format(fmt) {
				var d = new Date()
				var o = {
					"M+": d.getMonth() + 1,
					"d+": d.getDate(),
					"h+": d.getHours(),
					"m+": d.getMinutes(),
					"s+": d.getSeconds(),
					"q+": Math.floor((d.getMonth() + 3) / 3),
					"S": d.getMilliseconds()
				};
				if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
				for (var k in o)
					if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[
						k]) : (("00" + o[k]).substr(("" + o[k]).length)));
				return fmt;
			},
			nowtime() {
				this.date = this.Format("yyyy-MM-dd");
				this.time = this.Format("hh:mm:ss");
				this.week = this.weeks[new Date().getDay()]
			},
			changeTab(e, index) {
				console.log('e===', e)
				let url = ''
				if (index == 2) {
					url = 'https://zhibo.chong0123.com:777/xgkj2.html'
				} else if (index == 1) {
					url = 'https://zhibo.2020kj.com:777/amkj2.html'
				}

				this.actionUrl = url
				this.cur = index
			}

		},
	}
</script>
<style>
	body{
		background:#fff;
	
		
		
	}
	.video-js {
		width: 6.5rem;
		height: 3.65rem;
	}
	
	html, body {
	    
	}
	
	ul, li, dd {
	    list-style: none;
	    padding: 0;
	    margin: 0;
	}
	
	.main {
		max-width: 960px;
		display: block;
		margin: auto;
		padding: 0;
		overflow: auto;
	}
	
	.clearfix, .layout, .container, .container-layout, .line, .line-small, .line-middle, .line-big, .nav-main, .nav-sub, .navbar {
	    display: block;
	}

	.advert {
		max-width: 960px;
		margin: 0 auto;
		text-align: center;
		background: url(http://a3.dl2.jxcdxt.cn/assets/images/33.jpg) repeat-y;
	}
	
	
	.kj-tit {
		height:30px;
		line-height:30px;
		padding:0 10px;
		color:#777;
		font-size:24px;
		background:#eee
	}
	.kj-tit-l {
		float:left
	}
	.kj-tit-r {
		float:right;
		color:#006afe
	}
	.kj-tit-r a {
		color:#006afe
	}
	.kj-box {
		padding-top:8px;
		text-align:center;
		font-size:26px
	}
	.kj-box ul {
		}.kj-box ul li {
		float:left;
		width:calc( (93%)/7)
	}
	.kj-box ul li.kj-jia {
		width:7%
	}
	.kj-box ul li dl {
		}.kj-box ul li dl dt {
		height:35px;
		line-height:30px;
		padding-right:3px;
		font-size:28px;
		color:#333;
		font-weight:700
	}
	.kj-box ul li dl dt {
		background:#fff
	}
	.kj-box ul li dl dt span{
		display:inline-block;width:30px;height:30px;background:#2389e9;color:#fff;border-radius:30px;
	}
	.kj-box ul li dl dd {
		height:30px;
		line-height:30px;
		margin-top:8px;
		border-top:solid 1px #eee
	}
	.kj-foot {
		height:30px;
		line-height:30px;
		padding:0 10px;
		color:red;
		font-size:24px;
		background:#f7f7f7
	}
	.wx-jin {
		color:#fc0
	}
	.wx-mu {
		color:#3c3
	}
	.wx-shui {
		color:#39f
	}
	.wx-huo {
		color:red
	}
	.wx-tu {
		color:#c90
	}
	.grey-txt {
		color:#ccc
	}
	#xz-tan-box {
		position:fixed;
		left:-100%;
		right:100%;
		top:0;
		bottom:0;
		text-align:center;
		font-size:0;
		z-index:1000
	}
	#xz-tan-box:after {
		content:"";
		display:inline-block;
		vertical-align:middle;
		height:100%;
		width:0
	}
	.xz-tan-box {
		display:inline-block;
		*display:inline;
		*zoom:1;
		vertical-align:middle;
		position:relative;
		right:-100%;
		width:90%;
		max-width:700px;
		color:#000;
		font-size:26px;
		border-radius:10px;
		background:#fff
	}
	.xz-tan-content {
		padding:20px 15px
	}
	.xz-tan-content ul {
		}.xz-tan-content ul li {
		float:left;
		width:48%;
		padding-right:4%
	}
	.xz-tan-content ul li:last-child {
		padding-right:0
	}
	.xz-tan-last {
		height:40px;
		border:solid 1px #ddd;
		border-radius:10px 10px 0 0;
		background:#eee;
		background-image:linear-gradient(to top,#fff,#aaa,#fff)
	}
	.xz-tan-last span {
		width:0;
		height:0;
		border-left:10px solid transparent;
		border-right:10px solid transparent;
		border-bottom:10px solid #333;
		position:relative;
		top:-8px
	}
	.xz-tan-next {
		height:40px;
		border:solid 1px #ddd;
		border-radius:0 0 10px 10px;
		background:#eee;
		background-image:linear-gradient(to top,#fff,#aaa,#fff)
	}
	.xz-tan-next span {
		width:0;
		height:0;
		border-left:10px solid transparent;
		border-right:10px solid transparent;
		border-top:10px solid #333;
		position:relative;
		top:25px
	}
	.xz-tan-input {
		padding:10px;
		border-left:solid 1px #ddd;
		border-right:solid 1px #ddd;
		background:#eee
	}
	.xz-tan-input input {
		width:100%;
		height:40px;
		line-height:40px;
		text-align:center;
		font-size:34px;
		border:0;
		background:0 0
	}
	.xz-tan-btn {
		}.xz-tan-btn a {
		height:50px;
		line-height:50px;
		margin-top:20px;
		font-size:28px;
		color:#fff;
		border-radius:10px;
		background:#006afe;
		display:block
	}
	.xz-black-bg {
		width:100%;
		max-width:800px;
		height:100%;
		position:fixed;
		top:0;
		z-index:999;
		background:#000;
		opacity:.6;
		filter:alpha(opacity=60)
	}
	.selectYearDialog {
		display:none
	}
	
	.kj-box ul li.kj-jia dl dt {
	    background: url(../assets/styles/images/kj-jia.png) center center no-repeat;
	    background-size: contain;
	}
	.menu {
		    background: #666;
	}
	.menu dt {
	    color: #fff;
	    text-align: center;
	    font-size: 42px;
	    padding: 0;
	    font-weight: 700;
	    letter-spacing: 8px;
	}
	.menu dt.line-60 {
	    line-height: 50px;
	}
	
	.kj-box ul li dl dt.bg_blue span {
		background-color: #06f;
		color: #fff
	}
	
	.kj-box ul li dl dt.bg_green span {
		background-color: #090;
		color: #fff
	}

	.kj-box ul li dl dt.bg_red span {
		background-color: red;
		color: #fff
	}
	
</style>