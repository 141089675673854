import axios from 'axios'
import { tansParams } from './utils'

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://adminin.aoccai830.com/api',
  // 超时
  timeout: 30000,
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem('token') || ''
    // get请求映射params参数
    let url = config.url
    if (config.method === 'get' && config.params) {
      url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    config.headers = {
      ...config.headers,
      'content-type': url.indexOf('/upload') > -1 ? 'multipart/form-data' : config.data.contentType || 'application/json',
      Authorization: 'Bearer ' + token
    }
    // console.log('header', config.headers);
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = Number(res.data.code) || 200

    return res.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
