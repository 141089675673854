<template>
  <div class="main" align="center">
    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
      >
        <tbody>
          <tr>
            <td>
              <table
                height="75"
                cellspacing="0"
                cellpadding="0"
                width="960"
                align="center"
                background="https://adminin.aoccai830.com/assets/images/33.jpg"
                border="0"
              >
                <tbody>
                  <tr>
                    <td
                      background="https://adminin.aoccai830.com/assets/images/11.jpg"
                      height="19"
                      style="line-height: 0"
                    ></td>
                  </tr>
                  <tr>
                    <td
                      background="https://adminin.aoccai830.com/assets/images/44.jpg"
                      height="14"
                      style="line-height: 0"
                    ></td>
                  </tr>
                  <tr>
                    <td height="38" align="center">
                      <img
                        border="0"
                        :src="'https://adminin.aoccai830.com/storage/' + logo"
                        width="920"
                        height="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      background="https://adminin.aoccai830.com/assets/images/44.jpg"
                      height="14"
                      style="line-height: 0"
                    >
                      <img
                        height="19"
                        src="https://adminin.aoccai830.com/assets/images/77.jpg"
                        width="960"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->

              <!--001-->

              <div>
                <div class="KJ-TabBox" style="height: 220px">
                  <ul>
                    <li
                      v-show="open_am == 1"
                      data-opt="{'color':'#ffffff','url':'https://zhibo.2020kj.com:777/amkj2.html','height':130}"
                      :class="cur == 1 ? 'cur' : ''"
                      style="
                        width: 220px;
                        text-align: center;
                        display: block;
                        flex: inherit;
                      "
                      @click="changeTab($event, 1)"
                    >
                      澳门六合彩
                    </li>
                    <li
                      v-show="open_hk == 1"
                      data-opt="{'color':'#ffffff','url':'https://zhibo.chong0123.com:777/xgkj2.html','height':130}"
                      :class="cur == 2 ? 'cur' : ''"
                      @click="changeTab($event, 2)"
                    >
                      香港六合彩
                    </li>
                  </ul>
                  <div class="cur">
                    <router-link
                      class="history-link"
                      target="_blank"
                      :to="{ path: '/history', query: { type: cur } }"
                      >历史记录</router-link
                    >
                    <!-- <iframe class="KJ-IFRAME" :src="actionUrl" width="100%" height="130" frameborder="0" scrolling="no"></iframe> -->
                    <div
                      id="xg"
                      class="list"
                      style="display: flex; flex-wrap: wrap"
                    >
                      <div
                        class="new_kj"
                        style="
                          width: 100vw;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          font-size: 20px;
                          font-weight: 600;
                          padding: 10px;
                        "
                      >
                        <span
                          >第<span class="qi">{{ currentData.last.qisu }}</span
                          >最新开奖结果:</span
                        >
                      </div>
                      <ul
                        style="
                          display: flex;
                          flex-wrap: nowrap;
                          width: 100%;
                          justify-content: space-around;
                          height: 70px;
                        "
                      >
                        <li
                          v-if="viewNumber >= 1"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma1.split(',')[0])
                            "
                            >{{ currentData.last.haoma1.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma1.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 2"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css20 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma2.split(',')[0])
                            "
                            >{{ currentData.last.haoma2.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma2.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 3"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css45 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma3.split(',')[0])
                            "
                            >{{ currentData.last.haoma3.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma3.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 4"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css25 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma4.split(',')[0])
                            "
                            >{{ currentData.last.haoma4.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma4.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 5"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css39 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma5.split(',')[0])
                            "
                            >{{ currentData.last.haoma5.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma5.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 6"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css15 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma6.split(',')[0])
                            "
                            >{{ currentData.last.haoma6.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma6.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 7"
                          style="display: flex;background-color: unset;wrap;justify-content: center;align-items: center;width:auto;height:auto"
                        >
                          <span class="icon icon-jia"></span>
                        </li>
                        <li class="default no" v-else>中</li>

                        <li
                          v-if="viewNumber >= 7"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css28 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma7.split(',')[0])
                            "
                            >{{ currentData.last.haoma7.split(",")[0] }}</span
                          >
                          <span class="c7">{{
                            currentData.last.haoma7.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                      </ul>
                      <div
                        class="next_kj"
                        style="
                          padding-top: 21px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          font-size: 32px;
                        "
                      >
                        第<span class="qi">{{ currentData.next.qisu }}</span
                        >开奖：<span id="nx"
                          >{{
                            Format(
                              "yyyy年MM月dd日 hh:mm:ss",
                              currentData.next.date_time
                            )
                          }} </span
                        >&nbsp;&nbsp;

                        <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--
		<div>
			<table cellspacing="0" cellpadding="0" width="960" align="center" border="0"
				background="https://adminin.aoccai830.com/assets/images/33.jpg">
				<tbody>
					<tr>
						<td background="https://adminin.aoccai830.com/assets/images/44.jpg" height="14" style="line-height: 0">
						</td>
					</tr>
					<tr>
						<td align="center" height="38">
							
							<div v-html="article[29]"></div>
							
						</td>
					</tr>
					<tr>
						<td background="https://adminin.aoccai830.com/assets/images/44.jpg" height="14" style="line-height: 0">
							<img height="19" alt="" src="https://adminin.aoccai830.com/assets/images/77.jpg" width="960">
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		-->
    <!--
		<div>

			<table cellspacing="0" cellpadding="0" width="960" align="center" border="0"
				background="https://adminin.aoccai830.com/assets/images/33.jpg">
				<tbody>
					<tr>
						<td background="https://adminin.aoccai830.com/assets/images/44.jpg" height="14" style="line-height: 0">
						</td>
					</tr>
					<tr>
						<td align="center" height="38">
							<!-- 资料开始 -->
    <!-- <div v-html="article[28]"></div>
				<!-- 资料结束 -->
    <!-- </td>
					</tr>
					<tr>
					<td height="14" style="line-height: 0">
						<img height="19" alt="" src="https://adminin.aoccai830.com/assets/images/77.jpg" width="960"></td>
					</tr> -->
    <!-- </tbody> -->
    <!-- </table> -->

    <!-- </div> 

			<div>
			  
			  <table cellspacing="0" cellpadding="0" width="960" align="center" border="0" background="https://adminin.aoccai830.com/assets/images/33.jpg">
			     <tbody> 
			      <tr>
			       <td background="https://adminin.aoccai830.com/assets/images/44.jpg" height="14" style="line-height: 0"></td>
			      </tr>
			      <tr>
			       <td align="center" height="38">
			  <!-- 资料开始 -->

    <!--001-->
    <!--
							<div v-for="(value, index) in article2">



								<div v-html="value.content"></div>
								<!-- <table style="border-collapse: collapse; font-weight: bold; font-family: 宋体;" border="1" width="920" cellspacing="0" cellpadding="0">
						<tbody>
						<tr>
						<td colspan="2" align="center" bgcolor="#FFCCFF" height="60"><span style="font-size: 24pt;">黄大仙AAA级大公开;准确率99%;拿高利贷也要大胆下注</span></td>
						</tr>
						<tr>
						<td align="left" bgcolor="#FFFFFF" width="365" height="55"><span style="font-size: 20pt; font-family: 宋体;">{{value.qishu}}必中四肖:</span>
						<span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
						 <i v-for="(item,index) in value.sixiao" :class="item == value.yixiaoyima1 ? 'active' : ''">{{item}}</i>
						</span></td>
						<td align="left" bgcolor="#FFFFFF" width="552" height="55"><span style="font-size: 20pt; font-family: 宋体;">必中⑧码:</span><span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
						
						<i v-for="(item,index) in value.bama" :class="item == value.yixiaoyima2 ? 'active' : ''">{{item}}</i>
						</span></td>
						</tr>
						<tr>
						<td align="left" bgcolor="#FFFFFF" width="365" height="55"><span style="font-size: 20pt; font-family: 宋体;">{{value.qishu}}必中三肖:</span><span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
							<i v-for="(item,index) in value.sanxiao" :class="item == value.yixiaoyima1 ? 'active' : ''">{{item}}</i>
						</span></td>
						<td align="left" bgcolor="#FFFFFF" width="552" height="55"><span style="font-size: 20pt; font-family: 宋体;">必中⑤码:</span><span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
						
						<i v-for="(item,index) in value.wuma" :class="item == value.yixiaoyima2 ? 'active' : ''">{{item}}</i>
						</span></td>
						</tr>
						<tr>
						<td align="left" bgcolor="#FFFFFF" width="365" height="55"><span style="font-size: 20pt; font-family: 宋体;">{{value.qishu}}必中二肖:</span><span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
							<i v-for="(item,index) in value.erxiao" :class="item == value.yixiaoyima1 ? 'active' : ''">{{item}}</i>
						</span></td>
						<td align="left" bgcolor="#FFFFFF" width="552" height="55"><span style="font-size: 20pt; font-family: 宋体;">必中③码:</span><span style="font-size: 23pt; color: #ff0000; font-family: 宋体;">
						
						<i v-for="(item,index) in value.sanma" :class="item == value.yixiaoyima2 ? 'active' : ''">{{item}}</i>
						
						</span></td>
						</tr>
						<tr>
						<td colspan="2" align="center" bgcolor="#FFFFFF"><span style="font-size: 21pt; font-family: 宋体;">{{value.qishu}}:推荐一肖一码</span><span style="font-size: 41pt; color: #ff0000; font-family: 宋体;">＜</span><span class="yixiao" style="font-size: 41pt; color: #ff0000; font-family: 宋体;">
						
						<i v-for="(item,index) in value.yixiaoyima" :class="value.is_reward ? 'active' : ''">{{item}}</i>
						
						</span><span style="font-size: 41pt; color: #ff0000; font-family: 宋体;">＞</span><span style="font-size: 21pt; font-family: 宋体;">带上全村人一起来验证</span></td>
						</tr>
						</tbody>
					</table> -->
    <!--
								<div class="advert" v-if="adverts[index] && index == 0 && adverts[index].status == 1">
									<a :href="adverts[index].url" target="_blank">
										<img style="width: 97%"
											:src="'https://adminin.aoccai830.com/storage/' + adverts[index].cover_img" />
									</a>
								</div>

							</div>




							<!-- 资料结束 -->
    <!--
						</td>
					</tr>
					<tr>
						<td height="14" style="line-height: 0">
							<img height="19" alt="" src="https://adminin.aoccai830.com/assets/images/77.jpg" width="960">
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		-->

    <div class="advert" v-if="adverts[1]">
      <a :href="adverts[1].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[1].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[2]">
      <a :href="adverts[2].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[2].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[3]">
      <a :href="adverts[3].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[3].cover_img"
        />
      </a>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[3]">}</div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[4]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->

              <div v-html="article[5]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[6]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[7]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[8]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    =

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[9]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[10]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[11]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="advert" v-if="adverts[4]">
      <a :href="adverts[4].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[4].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[5]">
      <a :href="adverts[5].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[5].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[6]">
      <a :href="adverts[6].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[6].cover_img"
        />
      </a>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[12]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[13]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[14]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[15]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[16]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[17]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[18]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <!--018-->
              <div v-html="article[19]"></div>

              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="advert" v-if="adverts[7]">
      <a :href="adverts[7].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[7].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[8]">
      <a :href="adverts[8].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[8].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[9]">
      <a :href="adverts[9].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://adminin.aoccai830.com/storage/' + adverts[9].cover_img"
        />
      </a>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[20]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[21]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[21]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[22]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[23]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->

              <div v-html="article[24]"></div>

              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[25]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td height="14" style="line-height: 0">
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[26]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            >
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://adminin.aoccai830.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            ></td>
          </tr>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[27]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/44.jpg"
              height="14"
              style="line-height: 0"
            >
              <img
                height="19"
                alt=""
                src="https://adminin.aoccai830.com/assets/images/77.jpg"
                width="960"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
      >
        <tbody>
          <tr>
            <td
              background="https://adminin.aoccai830.com/assets/images/88.jpg"
              height="19"
              style="line-height: 0"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
//port "@videojs/http-streaming"

//import { includes } from "core-js/library/fn/dict";
import { init } from "echarts";
import { indexFn } from "/api/index.js";
export default {
  name: "board",
  components: {},
  data() {
    return {
      time: "",
      week: "",
      open_hk: 1,
      open_am: 1,
      type: "am",
      fun:null,
      timeId:"",
      ram_change:0,
      currentData: {},
      //date:'',
      article: {},
      actionUrl: "https://zhibo.2020kj.com:777/amkj2.html",
      cur: 1,
      logo: "images/08cfe3149b4d15a0c004caeabc12af2c.png",
      adverts: [],
      article2: [],
      adverts2: [],
      start: "1693825086",
      hours: 0,
      second: 0,
      midear: 0,
      hkdata: {},
      color: [
        [
          "06",
          "05",
          "17",
          "16",
          "28",
          "27",
          "39",
          "38",
          "49",
          "11",
          "22",
          "21",
          "33",
          "32",
          "44",
          "43",
        ],
        [
          "18",
          "30",
          "29",
          "40",
          "02",
          "01",
          "12",
          "13",
          "24",
          "23",
          "35",
          "34",
          "46",
          "45",
          "08",
          "07",
          "19",
        ],
        [
          "42",
          "41",
          "04",
          "03",
          "15",
          "14",
          "26",
          "25",
          "36",
          "37",
          "48",
          "47",
          "10",
          "09",
          "20",
          "31",
        ],
      ],
      viewNumber: 0,
      currentHkqisu: 0,
      nextHkqisu: 0,
      showTimeLeft: true,
    };
  },
  computed: {
    date() {
      if (this.start == -1) {
        return "没有新的一期了";
      }
      let second = this.second;
      let midear = this.midear;
      if (second < 10) {
        second = "0" + second;
      }
      if (midear < 10) {
        midear = "0" + midear;
      }
      return this.hours + ":" + second + ":" + midear;
    },
  },

  mounted() {
    this.initHk();
    let that = this;

    indexFn({}, "getinfo")
      .then((res) => {
        //console.log('data',res.data)
        res.data.forEach(function (val, index) {
          console.log(val);
          that.article[val.module] = val.content;
        });
        console.log(this.article);
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getlogo")
      .then((res) => {
        console.log("res2", res);
        that.logo = res.data.logo;
        this.$forceUpdate();
      })
      .catch((err) => {});

    indexFn({}, "getnotice")
      .then((res) => {
        //that.adverts = res.data

        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getnotice2")
      .then((res) => {
        console.log("getnotice2", res);
        //that.adverts2 = res.data
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getarticle")
      .then((res) => {
        console.log("res4", res);
        res.data.forEach(function (val, index) {
          let art = val;
          // art.bama = val.bama.split(',')
          // art.erxiao = val.erxiao.split(',')
          // art.sanma = val.sanma.split(',')
          // art.sanxiao = val.sanxiao.split(',')
          // art.sixiao = val.sixiao.split(',')
          // art.wuma = val.wuma.split(',')
          // art.yixiaoyima = val.yixiaoyima.split(',')

          // art.yixiaoyima1 = art.yixiaoyima[0] || ''
          // art.yixiaoyima2 = art.yixiaoyima[1] || ''

          console.log(art);
          that.article2.push(art);
        });

        console.log("article2", that.article2);

        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });
  },

  beforeDestroy() {},
  methods: {
    getUrl(shuzi) {
      // return "background:url(https://adminin.aoccai830.com/assets/images/red-color.png)";

      if (this.color[0].indexOf(shuzi + "") != -1) {
        return "background:url(https://adminin.aoccai830.com/assets/images/green-color.png);";
      }
      if (this.color[1].indexOf(shuzi + "") != -1) {
        return "background:url(https://adminin.aoccai830.com/assets/images/red-color.png);background-position: center;height: 50px;padding: 5px 0px 0px 0px; background-size: 50% 100%;background-repeat: no-repeat";
      }
      if (this.color[2].indexOf(shuzi + "") != -1) {
        return "background:url(https://adminin.aoccai830.com/assets/images/blue-color.png);background-position: center;height: 50px;padding: 5px 0px 0px 0px; background-size: 50% 100%;background-repeat: no-repeat";
      }
    },
    Format(fmt, date) {
      var d = date != undefined ? new Date(date) : new Date();
      console.log("d----", d);
      var o = {
        "M+": d.getMonth() + 1,
        "d+": d.getDate(),
        "h+": d.getHours(),
        "m+": d.getMinutes(),
        "s+": d.getSeconds(),
        "q+": Math.floor((d.getMonth() + 3) / 3),
        S: d.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    changeType() {
      let date1 = this.Format("yyyy-MM-dd");
      //console.log("date---", date1);
      console.log(this.currentHkqisu,this.nextHkqisu )
      if (
        this.sDuringDate(date1 + " 15:15", date1 + " 20:45") &&
        this.currentHkqisu != this.nextHkqisu
      ) {
        //window.clearInterval(this.fun);
        this.showTimeLeft = false;
        this.viewNumber = 0;
        for (let i = 1; i <= 7; i++) {
          (function (i, that) {
            // 注意这里是形参
            setTimeout(function () {
				      that.viewNumber += 1;
              console.log("viewNumber", that.viewNumber);
              if (that.viewNumber >= 7) {
                that.showTimeLeft = true;
                that.initHk();
              }
            }, 10000 * i); // 注意这里的i不是全局的i
          })(i,  this);
        }
      } else {
        this.viewNumber = 7;
      }
      if (this.type == "hk") {
        this.currentData = this.hkdata.hk;
        console.log("当前数据", this.currentData);
        // console.log("获取时间:"+Date().getTime(this.hkdata.hk.next.date_time))
        this.start = this.hkdata.hk.next
          ? parseInt(new Date(this.hkdata.hk.next.date_time).getTime() / 1000)
          : -1;
      } else {
        this.currentData = this.hkdata.am;
        this.start = this.hkdata.am.next
          ? parseInt(new Date(this.hkdata.am.next.date_time).getTime() / 1000)
          : -1;
      }
    },
    initHk() {
      let that = this;
      
      let fun = () => {
        clearInterval(this.timeId);
        indexFn({}, "gethkdata")
          .then((res) => {
            this.hkdata = res.data;
            if (this.type == "hk") {
              this.currentData = this.hkdata.hk;
            } else {
              this.currentData = this.hkdata.am;
            }
            let currentHkqisu = localStorage.getItem("hk_qisu");
            that.currentHkqisu = currentHkqisu || "";
            console.log("currentHkqisu", currentHkqisu);

            that.nextHkqisu = this.hkdata.am.next.qisu || "";
            localStorage.setItem("hk_qisu", that.nextHkqisu);
            let result;
            this.open_hk = this.hkdata.open_hk.value;
            this.open_am = this.hkdata.open_am.value;
            if (this.open_am != 1) {
              this.changeTab("", 2);
            }
            
            that.changeType();
            this.timeId = setInterval(() => {
              let date = parseInt(new Date().getTime() / 1000);

              result = that.start - date;
              if (result <= 0) {
                clearInterval(this.timeId);
                if(that.currentHkqisu == that.nextHkqisu){
                  that.initHk();
                }
              }else{
                if(that.currentHkqisu == that.nextHkqisu){
                  if(that.ram_change == 3){
                    that.ram_change = 0;
                    that.initHk();
                  }else{
                    that.ram_change += 1;
                  }
                }
              }
              that.hours = parseInt(result / (60 * 60));
              that.second = parseInt(result / 60 - that.hours * 60);
              that.midear = result % 60;
            }, 1000);
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
      fun();
      // window.clearInterval(this.fun);
      // window.setInterval(this.fun, 30000);
    },
    nowtime() {
      this.date = this.Format("yyyy-MM-dd");
      this.time = this.Format("hh:mm:ss");
      this.week = this.weeks[new Date().getDay()];
    },
    changeTab(e, index) {
      console.log("e===", e);
      // let url = ''
      // if(index == 2){
      // 	url = 'https://zhibo.chong0123.com:777/xgkj2.html'
      // }else if(index == 1){
      // 	url = 'https://zhibo.2020kj.com:777/amkj2.html'
      // }
      if (index == 1) {
        this.type = "am";
        this.changeType();
      } else {
        this.type = "hk";
        this.changeType();
      }
      // this.actionUrl = url
      this.cur = index;
    },
    toHistory() {
      this.$router.replace("history");
    },

    sDuringDate: function (beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
.video-js {
  width: 6.5rem;
  height: 3.65rem;
}

.icon-jia {
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  background: url(../assets/styles/images/and.png) center center no-repeat;
  background-size: contain;
}

.KJ-TabBox ul {
  justify-content: flex-start;
}

.KJ-TabBox li {
  width: 106px;
  flex-grow: 1;
  display: flex;
}

.KJ-TabBox li span {
  display: block;
  width: 106px;
}

.KJ-TabBox li.default {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 24px;
  font-weight: bold;
  color: #e03e2d;
  width: 106px;
}

.KJ-TabBox li.no {
  visibility: hidden;
}

.advert {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  background: url(https://adminin.aoccai830.com/assets/images/33.jpg) repeat-y;
}

.bg {
  background-position: center !important;
  height: 50px !important;
  padding: 5px 0px 0px 0px !important;
  background-size: 50% 100% !important;
  background-repeat: no-repeat !important;
}
</style>