import request from "/utils/request.js"

// 公用请求函数return处理机制
let fn = (urls, params, type) => {
	let msg = urls[type || 'list'],
		def_md = 'get'; // 默认请求方式
		console.log(type);
		console.log(urls);
	msg = typeof msg == 'string' ? {
		method: def_md,
		url: msg
	} : Array.isArray(msg) ? {
		url: msg[0],
		method: msg[1]
	} : msg;
	// {
	// 	url: msg[0],
	// 	method: msg[1]
	// }
	//console.log(msg)
	return request(msg.url, {
		data: params || {},
		method: msg.method || def_md,
		contentType: msg.contentType || ''
	})
}

// 接口集合
export function indexFn(params, type, others = {}) {	
	let urls = {
		tops: '/tops', // 头条播报
		info: '/screen/info',
		weather: '/photos/get_weather',
		ssp: '/screen/clapinfo',	// 随手拍
		getinfo: '/index/getinfo',	// 随手拍
		getlogo: '/index/getlogo',	// 随手拍
		getnotice: '/index/getnotice',	// 随手拍
		getarticle: '/index/getarticle',	// 随手拍
		getnewdata: '/index/getnewdata',	// 随手拍
		getamlist: '/index/getamlist',	// 随手拍
		getnotice2: '/index/getnotice2',
		gethkdata:'/index/gethkdata'
		
	}
	return fn(urls, params, type)
}
