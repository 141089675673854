<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
			}
		},
		mounted() {
			
		},
		methods: {

		},
	}
</script>